<template>
  <div class="about">
    <div class="banner">
      <img src="/assets/img/banner-5.jpg" alt="" class="banner">

      <div class="banner-text">
        <h2>{{ $t('navbar.about-us') }}</h2>
      </div>
    </div>

    <div class="container is-max-widescreen my-6">

      <div class="has-text-centered about-sgara">
        <p class="what-is-sgara" v-html="$t('about.sgara')"></p>
      </div>

      <div class="founders has-text-centered">
        <h5 class="content-sub-title mb-0 is-size-4" style="color: #7B9AFF">{{ $t('about.subtitle') }}</h5>
        <h2 class="content-title mb-5" style="color: #516ABB">{{ $t('about.title') }}</h2>

        <div class="columns is-mobile mt-3">
          <div class="column is-6-tablet is-12-mobile founder-item">
            <img src="/assets/img/jason.png" alt="Christoper Jason Sjarif - Sgara Founder & CEO">
            <p class="about-name">Christopher Jason Sjarif</p>
            <p class="about-position">Founder & CEO</p>
          </div>
          <div class="column is-6-tablet is-12-mobile founder-item">
            <img src="/assets/img/arrival.png" alt="Arrival Dwi Sentosa - Sgara Co-Founder & CTO">
            <p class="about-name">Arrival Dwi Sentosa</p>
            <p class="about-position">Co-Founder & CTO</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.about {
  .banner {
    position: relative;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-text {
      position: relative;
      z-index: 2;
      color: #FFFFFF;
      text-transform: capitalize;
    }

    h2 {
      font-size: 3rem;
      font-weight: bold;
    }

    img {
      position: absolute;
      vertical-align: middle;
      width: 100%;
      object-fit: cover;
      filter: brightness(.65);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .about-sgara {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .about-mission {
    margin-bottom: 5rem;

    div {
      z-index: 2;
      position: relative;
    }

    .content-title-left {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 120px;
        height: 120px;
        background-color: #E4EFFC;
        z-index: 1;
        border-radius: 100%;
        transform: translate(-35%, -35%);
      }
    }

    p {
      color: #7C859C;
      font-size: 1.2rem;
      margin-left: 2rem;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      text-align: center;

      .content-title-left {
        display: flex;

        > div {
          &:first-child {
            margin-right: .5rem;
          }
        }
      }

      p {
        margin-left: 0;
      }
    }
  }

  .founders {
    margin-bottom: 5rem;
    position: relative;

    &::before {
      position: absolute;
      right: 0;
      top: 0;
      width: 128px;
      height: 128px;
      background-image: url("/assets/img/dot.png");
      background-size: 32px;
      content: '';
    }
  }

  .founder-item {
    img {
      margin-bottom: 1.25rem;
      max-width: 280px;
    }

    .about-name {
      font-size: 1.45rem;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      margin-bottom: .5rem;
    }

    .about-position {
      color: rgba(0, 0, 0, 0.6);
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  .what-is-sgara {
    font-size: 1.2rem;
  }
}
</style>

<script>
export default {
  name: 'AboutUs',
}
</script>
